
import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import get from "lodash/get";

import { CHATBOT_TYPE } from "@containers/HelpCenter/APIs/constants";
import { getHCChatBotType } from "@containers/HelpCenter/utils/index.js";

import TicketHeaderDescription from "./TicketHeaderDescription";
import TicketDateDescription from "./TicketDateDescription";
import TicketBottomDetail from "./TicketBottomDetail";
import { KEY_TO_MAP, getTicketkey } from "./TickeCardCommon";

import "./style.scss";

const TicketCard = ({
	isLandingPage,
	handleNavigate,
	ticketCardData,
	imageStatusWidth,
	ticketAPIResponse,
	activeTabId,
	index
}, ref) => {
	const getTicketDescription = () => {
		let subCategory = getTicketkey(ticketCardData, KEY_TO_MAP.SUB_CATEGORY) || "";
		let leafCategory = getTicketkey(ticketCardData, KEY_TO_MAP.LEAF_CATEGORY) || "";
		return (<div className="info-data">
			{(subCategory ||
				leafCategory) &&
				subCategory?.concat("  ", leafCategory)}
		</div>);
	}

	let chatbotType = getHCChatBotType(),
		showBottomCardDetails = ticketCardData?.description?.length > 0 && (chatbotType === CHATBOT_TYPE.ORISERVE || isLandingPage);

	return (
		<>
			<div className="ticket">
				<div className="content-area">
					{<TicketHeaderDescription
						status={getTicketkey(ticketCardData, KEY_TO_MAP.STATUS)}
						id={getTicketkey(ticketCardData, KEY_TO_MAP.TICKET_ID)}
						imageStatusWidth={imageStatusWidth}
						isLandingPage={isLandingPage}
					/>}
					{getTicketDescription()}
					{<TicketDateDescription
						status={getTicketkey(ticketCardData, KEY_TO_MAP.STATUS)}
						raisedDate={getTicketkey(ticketCardData, KEY_TO_MAP.RAISED_DATE)}
						closeDate={getTicketkey(ticketCardData, KEY_TO_MAP.CLOSED_DATE, true)}
						expectedResolutionDate={getTicketkey(ticketCardData, KEY_TO_MAP.EXPECTED_RESOLUTION_DATE)}
						statusChange={getTicketkey(ticketCardData, KEY_TO_MAP.STATUS_CHANGE)}
						reCloseComments={getTicketkey(ticketCardData, KEY_TO_MAP.RECLOSE_COMMENTS)}
						reOpenCounter={Number(ticketCardData?.reOpenCounter)}
						isLandingPage={isLandingPage}
						handleNavigate={handleNavigate}
						ticketAPIResponse={ticketAPIResponse}
						ref={ref?.[index]}
					/>}
					{showBottomCardDetails && (
						<TicketBottomDetail
							status={getTicketkey(ticketCardData, KEY_TO_MAP.STATUS)}
							closedDate={getTicketkey(ticketCardData, KEY_TO_MAP.CLOSED_DATE, true)}
							raisedDate={getTicketkey(ticketCardData, KEY_TO_MAP.RAISED_DATE)}
							id={getTicketkey(ticketCardData, KEY_TO_MAP.TICKET_ID)}
							ticketCardData={ticketCardData}
							ref={ref?.[index]}
							index={index}
							isLandingPage={isLandingPage}
							expectedResolutionDate={getTicketkey(ticketCardData, KEY_TO_MAP.EXPECTED_RESOLUTION_DATE)}
							reCloseComments={getTicketkey(ticketCardData, KEY_TO_MAP.RECLOSE_COMMENTS)}
							chatbotType={chatbotType}
							imageUploadAllowed={get(ticketCardData,'imageUploadAllowed')}
						/>
					)}
				</div>
			</div>
		</>
	);
};

TicketCard.propTypes = {
	getTicketDetails: PropTypes.func,
	ticketDetails: PropTypes.object,
	isLandingPage: PropTypes.bool,
	handleNavigate: PropTypes.func,
	ticketAPIResponse: PropTypes.array,
};

export default React.forwardRef(TicketCard);
