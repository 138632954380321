import { isEmpty } from "lodash";
import * as moment from "moment";

import { CHATBOT_TYPE } from "@containers/HelpCenter/APIs/constants";
import { getHCChatBotType } from "@containers/HelpCenter/utils/index.js";

export const KEY_TO_MAP = {
    TICKET_ID: 'ticketId',
    STATUS: 'status',
    DESCRIPTION: 'description',
    LEAF_CATEGORY: 'leafCategory',
    SUB_CATEGORY: 'subCategory',
    RAISED_DATE: 'raisedDate',
    CLOSED_DATE: 'closeDate',
    EXPECTED_RESOLUTION_DATE: 'expectedResolutionDate',
    RECLOSE_COMMENTS: 'recloseComments',
    REOPENING_COMMENTS: 'reopeningComments',
    STATUS_CHANGE: 'statusChange',
    CHANGE_DATE: 'changeDate'
};

export const CHATBOT_MAPPED_KEY = {
    ORISERVE_TICKET_KEYS: [
        { label: KEY_TO_MAP.TICKET_ID, keyName: 'id' },
        { label: KEY_TO_MAP.STATUS, keyName: 'status' },
        { label: KEY_TO_MAP.DESCRIPTION, keyName: 'description' },
        { label: KEY_TO_MAP.LEAF_CATEGORY, keyName: 'leafCategory' },
        { label: KEY_TO_MAP.SUB_CATEGORY, keyName: 'subCategory' },
        { label: KEY_TO_MAP.RAISED_DATE, keyName: 'creationDate' },
        { label: KEY_TO_MAP.EXPECTED_RESOLUTION_DATE, keyName: 'expectedResolutionDate' },
        { label: KEY_TO_MAP.RECLOSE_COMMENTS, keyName: 'recloseComments' },
        { label: KEY_TO_MAP.REOPENING_COMMENTS, keyName: 'reopeningComments' },
        { label: KEY_TO_MAP.STATUS_CHANGE, keyName: 'statusChange' },
        { label: KEY_TO_MAP.CHANGE_DATE, keyName: 'changeDate' },
    ],
    S360_KEYS: [
        { label: KEY_TO_MAP.TICKET_ID, keyName: 'srNumber' },
        { label: KEY_TO_MAP.STATUS, keyName: 'status' },
        { label: KEY_TO_MAP.LEAF_CATEGORY, keyName: 'srSubArea' },
        { label: KEY_TO_MAP.SUB_CATEGORY, keyName: 'srArea' },
        { label: KEY_TO_MAP.RAISED_DATE, keyName: 'srOpenDate' },
        { label: KEY_TO_MAP.EXPECTED_RESOLUTION_DATE, keyName: 'srDueDate' },
        { label: KEY_TO_MAP.CLOSED_DATE, keyName: 'srClosedDate' },
    ]
};

export const TICKET_DATE_EXTENDED_MSG = "We are sorry it is taking longer than expected. We will resolve the ticket as soon as possible.";

/**
 * 
 * @param {*} obj 
 * @param {*} key 
 * @returns string
 */
export const findKeyFromObject = (obj, key) => {
    if (isEmpty(obj)) return null;
    if (obj.hasOwnProperty(key)) {
        return obj[key];
    } else {
        for (const k in obj) {
            if (obj[k] !== null && typeof obj[k] === 'object') {
                const result = findKeyFromObject(obj[k], key);
                if (result !== undefined) {
                    return result;
                }
            }
        }
    }
}

/**
 * @function getTicketkey - Get mapped value for a key from an object
 * @param {*} ticketCardData  - Object
 * @param {*} keyToMap - key Name
 * @param {*} activeTabId 
 * @returns string
 */
export const getTicketkey = (ticketCardData, keyToMap, showClosedDate = false) => {
    let chatbotType = getHCChatBotType(),
        mappedKeys = (chatbotType === CHATBOT_TYPE.ORISERVE) ? CHATBOT_MAPPED_KEY.ORISERVE_TICKET_KEYS : CHATBOT_MAPPED_KEY.S360_KEYS,
        key = mappedKeys.find(item => item.label === keyToMap)?.keyName;
    if (chatbotType === CHATBOT_TYPE.ORISERVE && keyToMap === KEY_TO_MAP.CLOSED_DATE) {  // closedDate handling for oriserve
        return showClosedDate ? ticketCardData?.resolutionDate : ticketCardData?.expectedResolutionDate;
    }
    if (chatbotType === CHATBOT_TYPE.S360 && keyToMap === KEY_TO_MAP.CLOSED_DATE) {
        return showClosedDate ? findKeyFromObject(ticketCardData, key) : ticketCardData?.srDueDate;
    }
    return findKeyFromObject(ticketCardData, key);
}


export const checkIfDateExceededExpectedDate = (expectedDate) => {
    let currentDate = moment().format("lll"),
        formatedExpectedDate = moment.utc(expectedDate).format("lll"),
        extendedMinCount = moment(currentDate).diff(formatedExpectedDate, "minutes");
        
    return extendedMinCount > 0;
}