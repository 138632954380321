import React, { useRef, useState, useImperativeHandle, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";
import { get } from "lodash";

import { openPopup } from "@common/Modal/action";
import { MODALS } from "@common/Modal/constants";
import { getKey } from "@utils/storage";
import { LOCALSTORAGE } from "@utils/constants";

import { CHATBOT_TYPE, TICKET_STATE } from "../../APIs/constants";
import CommentCard from "../CommentCard";
import ReopenTicket from "../ReopenTicket";
import ProgressStep from "../ProgressStep";
import { checkIfDateExceededExpectedDate } from "./TickeCardCommon";
import TicketFeedbackHC from "../TicketFeedbackHC";

import { getTicket, uploadFeedbackImage } from "@containers/HelpCenter/APIs/action";
import ImageUploadIcon from '@assets/images/file-attachment-icon.svg'
import { showToast } from "@utils/common";

const TicketBottomDetail = ({
    status,
    raisedDate,
    id,
    ticketCardData,
    isLandingPage,
    expectedResolutionDate,
    reCloseComments,
    chatbotType,
    closedDate,
    imageUploadAllowed,
}, ref) => {
    const dispatch = useDispatch();

    const [expand, setExpand] = useState(false);
    const [isTransition, setTransition] = useState(false);
    const [reOpenTicket, setReopenTicket] = useState(false);

  const hcFeedbackExpiryInDays = useSelector(state => get(state.headerDetails, "configResponse.data.config.helpCenterInfo.hcFeedbackExpiryInDays"));
  const hcEnableTicketFeedback = useSelector(state => get(state.headerDetails, "configResponse.data.config.helpCenterInfo.hcEnableTicketFeedback"));

    const listRef = useRef();

    useEffect(() => {
        let isStatusClose = status?.toLowerCase() == TICKET_STATE.CLOSE,
            reOpen = showReOpenCTA(raisedDate) && isStatusClose;
        setReopenTicket(reOpen);
    }, [raisedDate, status])

    const listExpand = async () => {
        setExpand(!expand);
        setTransition(false);
    };

    useImperativeHandle(ref, () => {
        return {
            ...ref?.current,
            isPrevExpand: expand,
            listRef: listRef.current,
            listExpand: (val) => setExpand(val),
            isTransition: () => setTransition(true),
            reOpenTicket: reOpenTicket
        }
    }, [expand, reOpenTicket])

    const showReOpenCTA = (raisedDate) => {
        const data = ticketCardData,
            originalDate = moment().format("lll"),
            formatedRaisedDate = moment.utc(raisedDate).format("lll"),
            remainingDays = moment(originalDate).diff(formatedRaisedDate, "days"),
            checkFalsy = remainingDays == 0 ? 1 : remainingDays,
            showReopen = checkFalsy <= 7 && Number(data.reOpenCounter) < 2 ? true : false;
        return showReopen;
    };

    const showTicketFeedbackForm = () => {
        const ticketClosedDate = moment.utc(closedDate).format("lll"),
            originalDate = moment().format("lll"),
            differenceInHours = moment(originalDate).diff(ticketClosedDate, "hours"),
            showForm = differenceInHours <= (hcFeedbackExpiryInDays * 24) ? true : false;

        console.log("Ticket Feedback:", originalDate, differenceInHours, ticketClosedDate)

        return showForm;
    };

    const openReopenTicketPopup = () => {
        dispatch(openPopup(MODALS.COMMENT_MODAL, {
            modalClass: "comment-modal-popup reopen-modal",
            childComponent: (
                <ReopenTicket
                    id={id}
                    status={status}
                    ticketCardData={ticketCardData}
                    isLandingPage={isLandingPage}
                />
            ),
        }));
    };

    const updateImage = async (e) => {
        e.preventDefault();
        let selectedFile = e.target.files[0];
        e.target.value = ''
        let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
        const maxSize = 512 * 1024; // 512 KB in bytes
        if (selectedFile && selectedFile.size < maxSize) {
            let splitName = selectedFile.name.split(".");
            let format = splitName[splitName.length - 1].toLowerCase();
            let isImageFormatIsRight = ["jpg", "jpeg", "png"].includes(format);
            if (isImageFormatIsRight) {
                const formData = new FormData();
                formData.append('imageFile', selectedFile);
                const updateImageResponse = await dispatch(uploadFeedbackImage(id, formData));
                if (updateImageResponse?.code === 0) {
                    showToast("Image Uploaded successfully", 4000);
                    let ticketPayload = { limit: 50, offset: 0 }
                    dispatch(getTicket(ticketPayload));
                } else {
                    showToast("File has been Failed to Upload", 4000);
                }
            } else {
                showToast('File format is not valid. Allowed Format : jpg, jpeg, png', 4000);
            }
        }
        else {
            showToast('File exceeded with Maximum Size (512KB). Allowed Format : jpg, jpeg, png', 4000);
        }
    }

    let isStatusReOpen = status?.toLowerCase() == TICKET_STATE.REOPEN,
        isStatusClose = status?.toLowerCase() == TICKET_STATE.CLOSE,
        isStatusOpen = status?.toLowerCase() == TICKET_STATE.OPEN,
        isTicketClosed = isStatusClose || status?.toLowerCase() === TICKET_STATE.CLOSED;

    let ticketFeedbackHC = isTicketClosed && hcEnableTicketFeedback && showTicketFeedbackForm();
    let imageUploadBlock = !isTicketClosed && imageUploadAllowed && !ticketFeedbackHC && chatbotType === CHATBOT_TYPE.ORISERVE;
    let isTicketStatusOpenAndDateExpired = (isStatusOpen || isStatusReOpen) && checkIfDateExceededExpectedDate(expectedResolutionDate);

    return (
        <div className="request-view">
            <div
                className={`${expand ? `collapse-show ${isTransition ? 'unset-transition' : ''}` : `collapse-hide ${isTransition ? 'unset-transition' : ''}`}`}
                ref={listRef}
            >
                {chatbotType === CHATBOT_TYPE.ORISERVE && (
                    <div className="sts-contr sts-comment ">
                        <CommentCard ticketCardData={ticketCardData} />
                    </div>
                )}
                {isLandingPage && (
                    <div className={`${ticketFeedbackHC
                        ? "ticket-status-wrapper" : imageUploadBlock
                            ? "ticket-wrapper-with-Feedback" : ""}`}>
                        <ProgressStep
                            isStatusClose={isTicketClosed}
                            isStatusOpen={isStatusOpen}
                            isStatusReOpen={isStatusReOpen}
                            raisedDate={raisedDate}
                            expectedResolutionDate={expectedResolutionDate}
                            reCloseComments={reCloseComments}
                            ref={ref}
                            status={status}
                            isTicketStatusOpenAndDateExpired={isTicketStatusOpenAndDateExpired}
                            ticketFeedbackHC={ticketFeedbackHC}
                            imageUploadBlock={imageUploadBlock}
                        />

                        {ticketFeedbackHC && <TicketFeedbackHC ticketId={id} chatbotType={chatbotType} />}
                        {imageUploadBlock && <div className="ticket-image-block">
                            <span className="image-text">Add Error Screenshot</span>
                            <span className="img-icon">
                                <input
                                    type="file"
                                    id={"imageUpload"}
                                    accept="image/*"
                                    onChange={updateImage}
                                />
                                <label
                                    htmlFor={"imageUpload"}
                                    onChange={updateImage}
                                >
                                    <img
                                        src={ImageUploadIcon}
                                        alt="Image-Upload" />
                                </label>
                            </span>
                        </div>}
                    </div>
                )}
            </div>
            <div className={`cta-contr ${(isLandingPage && reOpenTicket) ? 'cta-reopen' : ''}`}>
                <p
                    className="link-view"
                    onClick={() => listExpand()}
                >
                    {!expand ? "View Details" : "View Less"}
                </p>
                {reOpenTicket && (
                    <a
                        className="reopen-btn"
                        onClick={() => {
                            openReopenTicketPopup();
                        }}
                    >
                        Re-open
                    </a>
                )}
            </div>
        </div>
    )
}

export default React.forwardRef(TicketBottomDetail);