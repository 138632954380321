import React, { Component } from 'react';
import PropTypes from "prop-types";
import ConnectUs from './ConnectUs';
import Copyright from './Copyright';
import DownloadLinks from './DownloadLinks';
import TataSkyInfo from './TataskyInfo';
import ImportantLinks from './ImportantLinks';
import { dropDownDismissalCases, getVerbiages, capitalizeFirstLetter, getBreadcrumbContentType, getFormattedURLValue, getPiPageUrlContentType, isContentLive } from "@utils/common";
import { URL } from "@constants/routeConstants";

import './style.scss';
import { redirectToHomeScreen } from "@containers/BingeLogin/bingeLoginCommon";
import { BreadCrumbs } from "@common/Footer/BreadCrumbs";
import { CATEGORY_NAME, MENU_LIST, TAB_BREAKPOINT, CONTENTTYPE } from "@constants";
import { BROWSE_TYPE } from "@containers/BrowseByDetail/APIs/constants";
import MIXPANEL from "@constants/mixpanel";
import store from '@src/store';
import { get } from 'lodash';
import { getTitleAndDesc } from '@containers/PIDetail/PIDetailCommon';
import { connect } from 'react-redux';
import GlobalFooter from './GlobalFooter';
import PageFooter from './PageFooter';
import { calculateBreadCrumbWidth } from '@utils/common';
import BreadCrumbSchema from '@common/BreadCrumbSchema';
const getBreadCrumbName = (item) => {
    switch (item) {
        case URL.WATCHLIST:
            return MENU_LIST.BINGE_LIST;
        case URL.PROFILE:
            return MENU_LIST.EDIT_PROFILE;
        case URL.LANGUAGE:
            return `${getVerbiages(CATEGORY_NAME.LANGUAGE_SETTING)?.header || "Content Language"}`;
        case URL.DEVICE_MANAGEMENT:
            return MENU_LIST.MANGE_DEVICES;
        case URL.SWITCH_ACCOUNT:
            return MENU_LIST.SWITCH_ACCOUNT;
        case URL.SETTING:
            return MENU_LIST.SETTING;
        default:
            return item;
    }
}

const getLink = (item, history) => {
    const path = history.location.pathname;
    const pathHolderArr = path.toString().split('/');

    if ([URL.SEE_ALL, URL.TA_SEE_ALL, URL.TVOD, URL.CONTINUE_WATCHING_SEE_ALL, URL.RECOMMENDED_SEE_ALL, URL.WATCHLIST_SEE_ALL, URL.CONTINUE_PLAYING_SEE_ALL, URL.FAVOURITES_SEE_ALL].includes(pathHolderArr[1])) {
        return history?.location?.state?.railTitle;
    }

    return item.charAt(0).toUpperCase() + item.slice(1);
}

const getDetailPageBreadCrumbs = (history, updatedPath) => {
    let breadCrumbData = [], path = updatedPath, contentType, contentTitle, piMetaData;
    const pathHolderArr = updatedPath.toString().split('/');
    let isLive = isContentLive(pathHolderArr[2]);
    let state = store.getState();
    piMetaData = isLive ? get(state.PIDetails.liveDetail, "data.channelMeta") : get(state.PIDetails, "data.meta");
    if (piMetaData) {
        contentTitle = isLive ? piMetaData?.channelName : getTitleAndDesc(piMetaData, piMetaData?.contentType);
        contentType = getPiPageUrlContentType(piMetaData?.contentType);
        breadCrumbData.push(
            capitalizeFirstLetter(contentType),
            contentTitle,
        );
    }
    return breadCrumbData.map((updatedLink, index) => {
        let link = updatedLink;
        if (Number(index) + 1 === breadCrumbData.length) {
            return <BreadCrumbs path="id" key={index} link={link} className="change-colour shorttext" />
        }
        path = `/${getBreadcrumbContentType(contentType)}`;

        // if (![URL.CATEGORIES.toLowerCase(), BROWSE_TYPE.LANGUAGE.toLowerCase(), BROWSE_TYPE.GENRE.toLowerCase(), URL.PARTNER].includes(search?.breadCrumbSource)) {
        //     path = `/${contentType}`;
        // } else {
        //     path = history?.location?.state?.setPathIs
        // }

        // if (search?.breadCrumbSource === URL.PARTNER) {
        //     let partnerPath = history?.location?.state?.setPathIs?.pathname
        //     let routeUrl = partnerPath && partnerPath.split("/");
        //     link = routeUrl ? routeUrl[2] : link;
        // }
        // if(search?.breadCrumbSource === URL.SEARCH){
        //     let partnerPath = history?.location?.state?.setPathIs?.pathname;
        //     path= `${partnerPath}${history?.location?.state?.setPathIs?.search}`
        // }

        return <BreadCrumbs path={path} key={index} link={link} />
    });
};

const getEpisodePageBreadCrumbs = (history, updatedPath) => {
    let breadCrumbData = [], path = updatedPath, contentType, contentTitle;
    let state = store.getState();
    let piMetaData = get(state.PIDetails, "data.meta");
    if (piMetaData) {
        contentTitle = getTitleAndDesc(piMetaData, piMetaData?.contentType);
        contentType = getFormattedURLValue(piMetaData?.contentType);
        breadCrumbData.push(
            capitalizeFirstLetter(contentType),
            contentTitle,
        );
    }

    return breadCrumbData.map((updatedLink, index) => {
        let link = updatedLink;
        if (Number(index) + 1 === breadCrumbData.length) {
            return <BreadCrumbs path="id" key={index} link={link} className="change-colour shorttext" />
        }
        path = `/${getBreadcrumbContentType(contentType)}`;

        // if (![URL.CATEGORIES.toLowerCase(), BROWSE_TYPE.LANGUAGE.toLowerCase(), BROWSE_TYPE.GENRE.toLowerCase(), URL.PARTNER].includes(search?.breadCrumbSource)) {
        //     path = `/${search?.breadCrumbSource}`;
        // } else {
        //     path = history?.location?.state?.setPathIs
        // }

        // if (search?.breadCrumbSource === URL.PARTNER) {
        //     let partnerPath = history?.location?.state?.setPathIs?.pathname
        //     let routeUrl = partnerPath && partnerPath.split("/");
        //     link = routeUrl ? routeUrl[2] : link;
        // }

        return <BreadCrumbs path={path} key={index} link={link} />
    });
};

const getRecommendedSeeAllPageBreadCrumbs = (history, updatedPath, seeALL = false) => {
    let breadCrumbData = [];
    let contentLocation = history?.location?.state?.contentLocation;
    let title =  getSeeALLPath(seeALL, contentLocation)
    let path = updatedPath;
    title = seeALL && title?.toLowerCase() === URL.HOME ? '' : title;
    let contentType = !seeALL && updatedPath?.toString()?.split('/')?.[2];
    breadCrumbData.push(
        title,
        history.location?.state?.railTitle || "",
    )
    breadCrumbData = breadCrumbData?.filter((function (e) { return e }))
    !seeALL && contentType && (breadCrumbData?.unshift(capitalizeFirstLetter(contentType)))
    
    return breadCrumbData.map((link, index) => {
        path = index == 0 && !seeALL ? `/${contentType?.toLowerCase()}` : contentLocation;

        if (Number(index) + 1 === breadCrumbData.length) {
            return <BreadCrumbs path="id" key={index} link={link} className="change-colour shorttext" />
        }

        return <BreadCrumbs path={path} key={index} link={link} />
    });
};

const getBrowseByPageBreadCrumbs = (history, updatedPath) => {
    let breadCrumbData = [];
    let pathHolderArr = updatedPath?.split("/");
    pathHolderArr?.shift();

    let lastItemInUrl = pathHolderArr?.[pathHolderArr?.length - 1];
    let browseByArray = lastItemInUrl?.split("-");
    let categoryName = ![URL.BROWSE_BY_GENRE, URL.BROWSE_BY_LANGUAGE].includes(pathHolderArr?.[0]);
    let pathForGamesPage = pathHolderArr?.includes(URL.GAMES);

    pathHolderArr.forEach((data, index) => {
        if (categoryName && index == 0) {
            // Add Category in breadcrumb array along with defined path
            breadCrumbData.push({
                link: data,
                path: `/${data}`
            })
        }
        else if (!pathForGamesPage && browseByArray?.length === 2 && (index == categoryName ? 1 : 0)) {
            // Add Genre name if bbg and language name if bbl page in the middle
            let browseByValue = pathHolderArr?.includes(URL.BROWSE_BY_LANGUAGE)
                ? browseByArray?.[0]
                : browseByArray?.[1];
            let pathList = history?.location?.pathname?.split("/");
            pathList?.pop();
            breadCrumbData.push({
                link: browseByValue,
                path: `${pathList.join("/")}/${browseByValue}${history?.location?.search}`
            })
        } else if (!pathForGamesPage && index === pathHolderArr?.length - 1) {
            // If any filter is selected on bbl/bbg page add genre/lang name  , no path required for last item in breadcrumb
            breadCrumbData.push({
                link: data,
            })
        } else if (pathForGamesPage && index === 2) {
            // Add Genre title for games genre page and no path required , no path required for last item in breadcrumb
            breadCrumbData.push({
                link: data,
            })
        }
    });

    return breadCrumbData.map((item, index) => {
        let link = capitalizeFirstLetter(item?.link);
        if (Number(index) + 1 === breadCrumbData.length || item?.path === "id") {
            return <BreadCrumbs path="id" key={index} link={link} className="change-colour shorttext" />
        }
        return <BreadCrumbs path={item?.path} key={index} link={link} />
    });
}

const getBreadCrumbs = (history, categoryDropDown) => {
    let path = history.location.pathname;
    const pathHolderArr = path.toString().split('/');
    let isBBLBBGPage = pathHolderArr.includes(URL.BROWSE_BY_GENRE) || pathHolderArr.includes(URL.BROWSE_BY_LANGUAGE);

    if (pathHolderArr[1] === URL.SETTING && window.innerWidth > TAB_BREAKPOINT) {
        pathHolderArr.pop();
    }

    const arrLength = pathHolderArr.length;
    let breadCrumbClick;
    let isCustomBreadCrumbPage = [URL.RECOMMENDED_SEE_ALL, URL.DETAIL, URL.SEE_ALL].includes(pathHolderArr[1]) || isBBLBBGPage;

    let filterredPathHolderArr = !isCustomBreadCrumbPage && pathHolderArr.filter((item) => {
        if (item.length > 2 && ![URL.HOME, URL.PARTNER, URL.SEE_ALL, URL.TA_SEE_ALL, URL.CONTINUE_WATCHING_SEE_ALL, URL.WATCHLIST_SEE_ALL, URL.CONTINUE_PLAYING_SEE_ALL, URL.FAVOURITES_SEE_ALL].includes(item)) {
            return true;
        }
    });

    let breadCrumb = (filterredPathHolderArr || []).map((i, index) => {
        let item = i;
        if (item === URL.SETTING && window.innerWidth < TAB_BREAKPOINT && arrLength === 3) {
            path = `/${URL.SETTING}`;
        }

        item = getBreadCrumbName(item);

        let pathLink = getLink(item, history);
        if ([URL.SEO_RAILTYPE_PAGE, URL.SEO_SEARCHTYPE_PAGE].includes(pathHolderArr[1])) {
            pathLink = pathLink?.replace(/[^&\w]/g, ' ').replace(/[_]/g, ' ').replace(/\s+/g, " ");
        }
        if (Number(index) + 1 === arrLength - 1 || [URL.SEE_ALL, URL.TA_SEE_ALL, URL.TVOD, URL.CONTINUE_WATCHING_SEE_ALL, URL.PARTNER, URL.CONTINUE_PLAYING_SEE_ALL, URL.FAVOURITES_SEE_ALL].includes(pathHolderArr[1])) {
            return <BreadCrumbs path="id" key={index} link={pathLink} className="change-colour" />
        }

        if (item === URL.CATEGORIES) {
            breadCrumbClick = categoryDropDown;
        }
        return <BreadCrumbs path={path} key={index} link={pathLink} breadCrumbClick={breadCrumbClick} />
    });

    if (isBBLBBGPage) {
        breadCrumb = getBrowseByPageBreadCrumbs(history, path);
    }

    if (pathHolderArr[1] === URL.DETAIL) {
        breadCrumb = getDetailPageBreadCrumbs(history, path);
    }

    if (pathHolderArr[1] === URL.EPISODE) {
        breadCrumb = getEpisodePageBreadCrumbs(history, path);
    }

    if ([URL.SEE_ALL, URL.RECOMMENDED_SEE_ALL].includes(pathHolderArr[1])) {
        breadCrumb = getRecommendedSeeAllPageBreadCrumbs(history, path, pathHolderArr[1] === URL.SEE_ALL);
    }
    return [...breadCrumb, <BreadCrumbSchema breadCrumb={breadCrumb}/>];
}

const getSeeALLPath = (seeALL, contentLocation) => {
    let isPartner = contentLocation?.state?.partnerName,
        isSeeALLTitle = contentLocation?.pathname == URL.DEFAULT ? URL.HOME : contentLocation?.pathname?.replace(/\//g, ""),
        isCategorySeeALL = contentLocation?.pathname?.split('/')?.[1] === URL.CATEGORIES,
        categoryName = isCategorySeeALL && contentLocation?.pathname.split('/')?.[2];

    if (seeALL && isPartner) {
        return capitalizeFirstLetter(isPartner)
    } else if (seeALL && isCategorySeeALL) {
        return capitalizeFirstLetter(categoryName)
    } else if (seeALL) {
        return capitalizeFirstLetter(isSeeALLTitle)
    }
    return contentLocation?.state?.title
}
export default class Footer extends Component {
    componentDidMount(){
        calculateBreadCrumbWidth()
    }
    render() {
        const { history, categoryDropDown, pageFooterList, globalFooterList } = this.props;
        return (
            <footer onClick={() => dropDownDismissalCases('closeCondition')}>
                <div className="footer-wrapper">
                    <div className="footer-top-wrapper">
                        <div onClick={() => redirectToHomeScreen(history)}>
                            <i className="icon-home" /></div>
                        <div className="path-fetch">
                            {getBreadCrumbs(history, categoryDropDown)}
                        </div>
                    </div>
                    <PageFooter pageFooterData={pageFooterList} />
                    <GlobalFooter globalFooterData={globalFooterList} />
                    <div className="horizontal-line" />
                    <div className="footer-bottom-wrapper">
                        <div className="web-view">
                            <TataSkyInfo
                                history={history}
                            />
                        </div>
                        <ImportantLinks
                            history={history}
                        />
                        <div className="connection-details">
                            <ConnectUs
                                showTitle
                            />
                            <DownloadLinks source={MIXPANEL.VALUE.FOOTER} />
                            <div className="mobile-view">
                                <TataSkyInfo
                                    history={history}
                                />
                            </div>
                            <Copyright />
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
};

Footer.propTypes = {
    setSearch: PropTypes.func,
    configResponse: PropTypes.object,
    accountDropDown: PropTypes.func,
    switchAccountDropDown: PropTypes.func,
    notificationDropDown: PropTypes.func,
    history: PropTypes.object,
    homeScreenDataItems: PropTypes.object,
    categoryDropDown: PropTypes.func,
    liveMetaData: PropTypes.object,
};

