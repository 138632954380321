import React, { useEffect, useState } from 'react'
import moment from 'moment';

import { TICKET_STATE, TICKET_STATUS } from '@containers/HelpCenter/APIs/constants'
import { isMobile } from '@utils/common';

import './style.scss'

function ProgressStep({
    isStatusClose,
    isStatusOpen,
    expectedResolutionDate,
    raisedDate,
    reCloseComments,
    isStatusReOpen,
    status,
    isTicketStatusOpenAndDateExpired,
    ticketFeedbackHC,
    imageUploadBlock
}, ref) {
    const [activeStep, setActiveStep] = useState(0)
    const [steps, setSteps] = useState([])
    let showRequestDetail = isStatusReOpen || (isStatusClose && reCloseComments);

    useEffect(() => {
        const step = [
            {
                id: 0,
                label: TICKET_STATUS.CREATED.replace(/\:$/, ''),
                date: dateFormat(raisedDate)
            },
            {
                id: 1,
                label: isStatusReOpen ? TICKET_STATUS.RE_OPEN : TICKET_STATUS.IN_PROGRESS,
                date: isStatusReOpen ? dateFormat(ref.current?.getStatusDate(TICKET_STATE.REOPEN), true) : dateFormat('', false, true)
            },
            {
                id: 2,
                label: isTicketStatusOpenAndDateExpired
                    ? "Completion Pending"
                    : (isStatusOpen || isStatusReOpen)
                        ? TICKET_STATUS.SOLVED_BY.replace(/\:$/, '')
                        : TICKET_STATUS.COMPLETED,
                date: isTicketStatusOpenAndDateExpired
                    ? <div><span>Sorry for the delay.</span><span> We'll provide an update shortly!</span></div>
                    : (isStatusOpen || isStatusReOpen)
                        ? dateFormat(expectedResolutionDate)
                        : dateFormat(ref.current?.getStatusDate(TICKET_STATE.CLOSE, !!showRequestDetail), true)
            },
        ];

        setSteps(step);

        if(isStatusClose){
            setActiveStep(step.length-1)
        }else if(isStatusReOpen || isStatusOpen ){
            setActiveStep(1)
        }
    }, [status])

    const dateFormat = (date, isOld=false, currentDate=false) => {
        if(currentDate){
            return moment.utc().format('ll')
        }
        if(isOld){
            const dateTime = moment(date, 'DD/MM/YY, h a');
            return dateTime.format('MMM D, YYYY')
        }
        return moment.utc(date).format('ll')
    };

    return (
        <div className={`stepper-wrapper ${ticketFeedbackHC ? "stepper-with-feedback" : imageUploadBlock ? "stepper-with-image-block" : ""}`}>
            <div
                className={`stepper-head ${isMobile.any() ? 'vertical-stepper-head' : ''} `}
            >
                {steps?.map((el, i) => (
                    <div key={i} className={`stepper-step 
                    ${activeStep >= i ? 'is-complete' : ''} 
                    ${(i == 1 && (isStatusReOpen || isStatusOpen)) ? 'is-warning' : ''} 
                    ${activeStep === i ? 'is-active' : ''} 
                    ${i == 2 && isTicketStatusOpenAndDateExpired && imageUploadBlock && !isMobile.any() ? "is-date-excceed" : ""}
                    ${i == 2 && imageUploadBlock && !isMobile.any() ? "is-image-block" : ""}`}>
                        <div className="stepper-label">
                            <p className='step-label'>{el.label}</p>
                            <p className={`step-date ${i == 1 && isStatusClose ? 'is-close' : ''}`}>{el.date}</p></div>
                        <div className="stepper-indicator">
                            <span className="stepper-indicator-info">
                                {activeStep >= i && (
                                    <i className="icon-check stepper-tick" />
                                )}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default React.forwardRef(ProgressStep)