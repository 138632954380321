import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { get } from "lodash";
import isEmpty from "lodash/isEmpty";

import { LENGTH_CHECK, OTP_FLOW_VALUE, VALIDATE_SOURCE } from "@utils/constants";
import { isMobile } from "@utils/common";
import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";
import dataLayerConfig from "@utils/dataLayer";
import DATALAYER from "@utils/constants/dataLayer";
import appsFlyerConfig from "@utils/appsFlyer";
import APPSFLYER from "@utils/constants/appsFlyer";
import { rmnMaskingFunction } from "@containers/BingeLogin/bingeLoginCommon";

import OtpInpuBox from "./OtpInpuBox";
import trackEvent from "../../../utils/trackEvent";
import { trackMixpanelError, getOtpLength } from "../LoginCommon";
import { rmnValidateOtp, validateOfsSmartTvCode } from "../APIs/actions";

import "./style.scss";


class OTPComponent extends Component {
    constructor(props) {
        super(props);
        let otpInputLength = getOtpLength(this.props.otpValidateSource);
        this.state = {
            timerForOTPVerification: props.otpDuration,
            activeInputBox: 0,
            otpValueArray: new Array(otpInputLength).fill(""),
            errorMessage: null,
            autoFocus: false,
            otpResentCount: 1,
            otpInputLength: otpInputLength,
        };
        this.intervalId = "";
    }

    componentDidMount = () => {
        this.startTimer();
        this.toggleOtpBlockClass(true);
        this.autoReadSMS();
        const { otpValidateSource } = this.props;
        const isSourceSmartTv = otpValidateSource === VALIDATE_SOURCE.SMART_TV;
        !isSourceSmartTv && mixPanelConfig.trackEvent(MIXPANEL.EVENT.LOGIN_OTP_PAGE_VISIT, {
            [MIXPANEL.PARAMETER.SOURCE]: this.props.source,
            [MIXPANEL.VALUE.RMN]: this.props.rmn,
        });
    };

    componentWillUnmount = () => {
        clearInterval(this.intervalId);
        this.toggleOtpBlockClass(false);
    };

    autoReadSMS = (cb) => {
        // used AbortController with setTimeout so that WebOTP API (Autoread sms) will get disabled after 1min
        const signal = new AbortController();
        setTimeout(() => {
            signal.abort();
        }, 2 * 60 * 1000);

        this.getOTPAutomatically(signal);
    }

    getOTPAutomatically = async (signal) => {
        if ('OTPCredential' in window) {
            try {
                if (navigator.credentials) {
                    try {
                        await navigator.credentials
                            .get({ abort: signal, otp: { transport: ['sms'] } })
                            .then(content => {
                                if (content && content.code) {
                                    console.log(content, 'Success while reading otp')
                                    this.trackEventOnAutofill();
                                    this.handlePasteValue(content.code);
                                }
                            })
                            .catch(e => {
                                console.log(e, 'Error while reading otp')
                            });
                    }
                    catch (e) {
                        console.log(e, 'Error while reading otp')
                        return;
                    }
                }
            }
            catch (err) {
                console.log(err, 'Error while reading otp')
            }
        }
    }

    trackEventOnAutofill = () => {
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.LOGIN_OTP_RECEIVED, {
            [MIXPANEL.PARAMETER.SOURCE]: this.props.source,
            [MIXPANEL.VALUE.RMN]: this.props.rmn,
        });
    }

    toggleOtpBlockClass = (addClass = true) => {
        let element = document.querySelector('.login-with-otp-modal .popupSec');
        let hasClass = document.querySelector('.login-with-otp-modal .otp-container-block');

        if (!isEmpty(element)) {
            addClass && isEmpty(hasClass) && element.classList.add('otp-container-block');
            !addClass && !isEmpty(hasClass) && element.classList.remove('otp-container-block');
        }
    };

    startTimer = () => {
        this.intervalId = setInterval(() => {
            if (this.state.timerForOTPVerification === 0) {
                clearInterval(this.intervalId);
            } else {
                this.tick();
            }
        }, 1000);
    };

    tick = () => {
        this.setState((prevState) => ({
            timerForOTPVerification: prevState.timerForOTPVerification - 1,
        }));
    };

    handleOtpChange = async (otp) => {
        const { rmn, onOtpVerification, otpValidateSource, validateOfsSmartTvCode } = this.props;
        const isValidateFlow =  [VALIDATE_SOURCE.SMART_TV, VALIDATE_SOURCE.DELETE_ACCOUNT].includes(otpValidateSource);
        const signal = new AbortController();

        if (get(this.state, "errorMessage")) {
            this.setState({
                errorMessage: null,
            });
            signal.abort();
        }

        if (otp.length === this.state.otpInputLength && !isValidateFlow) {
            mixPanelConfig.trackEvent(MIXPANEL.EVENT.LOGIN_OTP_ENTER, {
                [MIXPANEL.PARAMETER.DEVICE_METHOD]: MIXPANEL.VALUE.WEB,
                [MIXPANEL.VALUE.RMN]: rmn,
                [MIXPANEL.PARAMETER.SOURCE]: this.props.source,
            });
            await this.props.rmnValidateOtp(rmn, otp);
            trackEvent.loginOtpEnter()
            const { otpResponse, rmnResponse } = this.props;

            if (otpResponse && otpResponse.code === 0) {
                onOtpVerification(otp);
                signal.abort();
            } else {
                clearInterval(this.intervalId);
                appsFlyerConfig.trackEvent(APPSFLYER.EVENT.LOGIN_FAILURE, {
                    [APPSFLYER.PARAMETER.TYPE]: APPSFLYER.VALUE.RMN,
                    [APPSFLYER.PARAMETER.AUTH]: APPSFLYER.VALUE.OTP,
                    [APPSFLYER.PARAMETER.VALUE]: rmnMaskingFunction(rmn),
                    [APPSFLYER.PARAMETER.REASON]: otpResponse?.message
                        ? otpResponse.message
                        : get(rmnResponse, "incorrectOtpVerbiage") || "Incorrect OTP",
                })
                const data = { loginErrorMessage: otpResponse?.message, rmn: rmn }
                trackEvent.loginFail(data)
                trackMixpanelError(otpResponse?.message, otpResponse?.code, this.props.source)
                this.setState({
                    errorMessage: otpResponse?.message
                        ? otpResponse.message
                        : get(rmnResponse, "incorrectOtpVerbiage") || "Incorrect OTP",
                    timerForOTPVerification: 0,
                    otpValueArray: new Array(this.state.otpInputLength).fill(""),
                });
                signal.abort();
            }
        }
        else if (otp.length === this.state.otpInputLength && otpValidateSource === VALIDATE_SOURCE.SMART_TV) {
            // this case will run in case of smart tv code validation
            let { handleCodeValidate } = this.props;
            let payload = {
                "redirectCode": otp,
            };
            await validateOfsSmartTvCode(payload);

            let { validateSmartTvResp } = this.props;
            if (!isEmpty(validateSmartTvResp) && validateSmartTvResp.code === 0) {
                return handleCodeValidate();
            } else {
                this.setState({
                    errorMessage: validateSmartTvResp?.message,
                    otpValueArray: new Array(this.state.otpInputLength).fill(""),
                }, () => {
                    this.focusInput(0);
                });
            }
        }
        else if(otp.length === this.state.otpInputLength && otpValidateSource === VALIDATE_SOURCE.DELETE_ACCOUNT){
            await this.props.rmnValidateOtp(rmn, otp, otpValidateSource);

            const { otpResponse, rmnResponse } = this.props;

            if (otpResponse && otpResponse.code === 0) {
                onOtpVerification(otp);
                signal.abort();
            } else {
                clearInterval(this.intervalId);             
                this.setState({
                    errorMessage: otpResponse?.message
                        ? otpResponse.message
                        : get(rmnResponse, "incorrectOtpVerbiage") || "Incorrect OTP",
                    timerForOTPVerification: 0,
                    otpValueArray: new Array(this.state.otpInputLength).fill(""),
                });
                signal.abort();
            }
        }
    };

    handleKeyEvent = (event) => {
        const pressedKey = event.key;
        const { activeInputBox, otpValueArray } = this.state;

        switch (pressedKey) {
            case "Backspace":
            case "Delete": {
                event.preventDefault();
                if (otpValueArray[activeInputBox]) {
                    this.changeCodeAtFocus("");
                } else {
                    this.focusPrevInput();
                }
                break;
            }
            case "ArrowLeft": {
                event.preventDefault();
                this.focusPrevInput();
                break;
            }
            case "ArrowRight": {
                event.preventDefault();
                this.focusNextInput();
                break;
            }
            default: {
                if (pressedKey.match(/^[^a-zA-Z0-9]+$/) && this.props.otpValidateSource === VALIDATE_SOURCE.SMART_TV) {
                    event.preventDefault();
                } else if (pressedKey.match(/^[^0-9]$/) && this.state.otpInputLength === LENGTH_CHECK.OTP) {
                    event.preventDefault();
                }
                break;
            }
        }
    };

    focusPrevInput = () => {
        this.focusInput(this.state.activeInputBox - 1);
    };

    focusNextInput = () => {
        this.focusInput(this.state.activeInputBox + 1);
    };

    focusInput = (inputIndex) => {
        const selectedIndex = Math.max(
            Math.min(this.state.otpInputLength - 1, inputIndex),
            0,
        );
        this.setState({
            activeInputBox: selectedIndex,
        });
    };

    changeCodeAtFocus = (value) => {
        if (value.length === this.state.otpInputLength) {
            this.handlePasteValue(value);
        } else {
            const { activeInputBox, otpValueArray } = this.state;
            const updatedotpValueArray = [...otpValueArray];
            updatedotpValueArray[activeInputBox] = value || "";
            this.setState({
                otpValueArray: updatedotpValueArray,
            });
            const otpValue = updatedotpValueArray.join("");
            this.handleOtpChange(otpValue);
        }
    };

    getRightValue = (value) => {
        let changedValue = value;
        if (!changedValue) {
            return changedValue;
        }
        else if (this.props.otpValidateSource === VALIDATE_SOURCE.SMART_TV) {
            return changedValue;
        } else {
            return Number(changedValue) >= 0 ? changedValue : "";
        }
    };

    handleOnChange = (event, value) => {
        let inputValue = value || event?.target?.value || "";
        inputValue = this.getRightValue(inputValue);
        if (!inputValue) {
            event.preventDefault();
            return;
        }
        else if (inputValue.length > 1 && inputValue.length !== this.state.otpInputLength) {
            // this check is added to handle the maxlength 6 while user manuaaly enters the OTP in input box
            event.preventDefault();
            return;
        }
        this.changeCodeAtFocus(inputValue);
        this.focusNextInput();
    };

    handleOnFocus = (inputIndex) => {
        const selectedIndex = Math.max(
            Math.min(this.state.otpInputLength - 1, inputIndex),
            0,
        );
        this.setState({
            activeInputBox: selectedIndex,
            autoFocus: true,
        });
    };

    resendOtp = async () => {
        const { timerForOTPVerification, errorMessage, otpResentCount, rmn } = this.state;
        if ((timerForOTPVerification !== 0 && !errorMessage) || (otpResentCount >= this.props.otpResentCount)) {
            return;
        }
        this.setState(prevState => ({
            timerForOTPVerification: this.props.otpDuration,
            activeInputBox: 0,
            otpValueArray: new Array(this.state.otpInputLength).fill(""),
            errorMessage: null,
            otpResentCount: prevState.otpResentCount + 1
        }));
        await this.props.handleResendOtp(true);
        if (this.props.selectedPlan) {
            dataLayerConfig.trackEvent(DATALAYER.EVENT.SUB_JOURNEY,
                {
                    [DATALAYER.PARAMETER.BUTTON_NAME]: DATALAYER.VALUE.RESEND_OTP,
                    [DATALAYER.PARAMETER.PACK_NAME]: this.props.selectedPlan?.productName,
                    [DATALAYER.PARAMETER.PACK_PRICE]: this.props.selectedPlan?.amountValue,
                }
            )
        } else {
            dataLayerConfig.trackEvent(DATALAYER.EVENT.SUB_JOURNEY, {
                [DATALAYER.PARAMETER.BUTTON_NAME]: DATALAYER.VALUE.RESEND_OTP,
            });
        }
        // dataLayerConfig.trackEvent(DATALAYER.EVENT.RESEND_OTP_LOGIN_JOURNEY)
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.LOGIN_OTP_RESEND, {
            [MIXPANEL.VALUE.RMN]: this.props.rmn,
            [MIXPANEL.PARAMETER.SOURCE]: this.props.source,
        })
        clearInterval(this.intervalId);
        this.startTimer();
        trackEvent.loginOtpResent();
        this.autoReadSMS();
    };

    handleOnBlur = (e, index) => {
        if (index < this.state.otpInputLength) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.setState({
            activeInputBox: -1,
        });
    };

    handlePasteValue = (value) => {
        const copiedOtpArr = Array.from(String(value), num => Number(num));
        this.setState({
            otpValueArray: copiedOtpArr,
            activeInputBox: copiedOtpArr.length - 1,
        });
        const otpValue = copiedOtpArr.join("");
        this.handleOtpChange(otpValue);
    };

    handleOtpPaste = async () => {
        let copiedValue = await navigator.clipboard.readText();
        this.handlePasteValue(copiedValue);
    };

    render() {
        const { rmn, rmnResponse, otpValidateSource, enableOtpUnmasking } = this.props;
        const { activeInputBox, otpValueArray, errorMessage, timerForOTPVerification, otpResentCount, otpInputLength } = this.state;
        const shouldTimerHide = timerForOTPVerification === 0 && !errorMessage;
        const shouldResendDisable = (timerForOTPVerification !== 0 && !errorMessage) || (otpResentCount >= this.props.otpResentCount);
        const isSmartTvValidate = otpValidateSource === VALIDATE_SOURCE.SMART_TV;

        return (
            <form>
                <div className={`otp-container ${isSmartTvValidate ? 'smart-tv-screen' : otpInputLength === LENGTH_CHECK.OTP_4 ? 'otp-four-container' : ''}`}>
                    {!isSmartTvValidate && <p className="otp-placeholder"> {get(rmnResponse, "enterOtpVerbiage") || "Please enter the OTP sent to"}+91 {(rmn)}</p>}
                    <ul className={`otp-input-wrapper ${errorMessage && "incorrect-otp"} ${enableOtpUnmasking ? '' : 'otp-mask'}`}>
                        {Array(otpInputLength)
                            .fill("")
                            .map((_, index) => (
                                <OtpInpuBox
                                    key={`OtpInpuBox-${index}`}
                                    type="tel"
                                    focus={activeInputBox === index}
                                    value={otpValueArray && otpValueArray[index]}
                                    autoFocus={isMobile.any() ? this.state.autoFocus : true}
                                    onFocus={() => {
                                        this.handleOnFocus(index);
                                    }}
                                    handlePasteValue={this.handlePasteValue}
                                    // maxlength for mobile is extended to 6 to support auto-fill in ios chrome, handling of input is done in handleOnChange func
                                    maxLength={(isMobile.any() && index === 0 && !isSmartTvValidate) ? otpInputLength : 1}
                                    onChange={this.handleOnChange}
                                    onKeyDown={this.handleKeyEvent}
                                    onBlur={(e) => this.handleOnBlur(e, index)}
                                    onPaste={(e) => this.handleOtpPaste(e)}
                                    otpValue={this.state.otpValueArray.join("")}
                                    otpValidateSource={otpValidateSource}
                                    otpInputLength={otpInputLength}
                                    trackEventOnAutofill={this.trackEventOnAutofill}
                                />
                            ))}
                    </ul>
                    <div className={`otp-resend-container ${shouldTimerHide && "hide-timer"}`}>
                        {isSmartTvValidate ?
                            <span className="error-message">{errorMessage}</span> :
                            <React.Fragment>
                                {errorMessage ? (
                                    <span className="error-message">{errorMessage}</span>
                                ) : timerForOTPVerification !== 0 ? (
                                    <div className="timer-container">
                                        {get(rmnResponse, "resendOtpInVerbiage") || "Resend OTP in"} {timerForOTPVerification} sec
                                    </div>
                                ) : null}
                                <div
                                    className={`resend-btn ${shouldResendDisable && "disabled-resend-button"}`}
                                >
                                    <a onClick={this.resendOtp}>{get(rmnResponse, "resendOtpHeading") || "Resend OTP"}</a>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        otpResponse: get(state.loginReducer, "otpResponse"),
        configResponse: get(state.headerDetails, "configResponse"),
        otpDuration: get(state.headerDetails, 'configResponse.data.config.otpDuration'),
        otpLengthConfig: get(state.headerDetails, 'configResponse.data.config.newOtpFlow'),
        otpResentCount: get(state.headerDetails, 'configResponse.data.config.otpResentCount'),
        validateSmartTvResp: get(state.loginReducer, 'validateOfsSmartTvCode'),
        rmnResponse: get(state.loginReducer, "rmnResponse.data"),
        enableOtpUnmasking: get(state.headerDetails, "configResponse.data.config.enableOtpUnmasking"),
    };
};

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(
            {
                rmnValidateOtp,
                validateOfsSmartTvCode,
            },
            dispatch,
        ),
    };
}

OTPComponent.propTypes = {
    rmnValidateOtp: PropTypes.func,
    onOtpVerification: PropTypes.func,
    handleResendOtp: PropTypes.func,
    rmn: PropTypes.string,
    otpDuration: PropTypes.number,
    otpResentCount: PropTypes.number,
    otpResponse: PropTypes.object,
    selectedPlan: PropTypes.object,
    rmnResponse: PropTypes.object,
    otpValidateSource: PropTypes.string,
    validateOfsSmartTvCode: PropTypes.func,
    otpLengthConfig: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(OTPComponent);